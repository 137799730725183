import {
  Container,
  Divider,
  Drawer,
  Text,
  Tooltip,
  UnstyledButton,
  useComputedColorScheme,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { getAuth, signOut } from "firebase/auth";
import React, { useState } from "react";
import {
  HiChevronRight,
  HiCog,
  HiFolder,
  HiHome,
  HiMenu,
  HiOutlineLogout,
  HiUser,
  HiViewGrid
} from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import useGlobalState, { globalProfile, globalToken } from "../../globalState";
import { openLogoutModal } from "../modal/confirm";
import AppLogo from "../static/AppLogo";
import "./Navbar.css";

interface NavbarLinkProps {
  icon?: typeof HiHome;
  iconComponent?: React.ReactNode;
  color?: string;
  label: string;
  active?: boolean;
  onClick?: () => void;
}

export default function Navbar(props: { demo?: boolean }) {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const { colorScheme, setColorScheme } = useMantineColorScheme({
    keepTransitions: true,
  });
  const computedColorScheme = useComputedColorScheme("dark");

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const [mobileMenuOpened, { toggle }] = useDisclosure();
  const [opened, { open, close }] = useDisclosure(false);
  const [profile, setProfile] = useGlobalState(globalProfile);
  const [token, setToken] = useGlobalState(globalToken);

  const location = useLocation();

  const explorerState = useAppSelector((state) => state.explorer);

  const getIconColor = (color: string) => {
    if (color) return color;
    return colorScheme === "dark"
      ? "var(--mantine-color-dark-2)"
      : "var(--mantine-color-dark-2)";
  };

  const getEmail = () => {
    if (props.demo) {
      if (!profile) return "";
      return profile["email"];
    }

    return getAuth().currentUser?.email;
  };

  const getDisplayName = () => {
    if (props.demo) {
      if (!profile) return "";
      return profile["name"];
    }

    return getAuth().currentUser?.displayName;
  };

  const getPhotoUrl = () => {
    if (props.demo) {
      return "https://picsum.photos/id/10/200/300";
    }

    return getAuth().currentUser?.photoURL;
  };

  const navbarPrimary = [
    {
      path: "/browse",
      icon: HiFolder,
      label: "Explorer",
      isActive: (path) => path.includes("/browse"),
      onClick: () => {
        // If already in explorer, do nothing
        if (location.pathname.includes("/browse")) {
          return;
        }

        // Otherwise, navigate to explorer taking into account the state
        if (explorerState.currentFolderId !== null) {
          navigate(
            `${props.demo ? "/demo" : ""}/browse/${
              explorerState.currentFolderId
            }`
          );
        } else {
          navigate(props.demo ? "/demo/browse" : "/browse");
        }
      },
    },
    {
      path: "/gallery",
      icon: HiViewGrid,
      label: "Gallery",
      onClick: () => navigate(props.demo ? "/demo/gallery" : "/gallery"),
    },
    //{ icon: HiMap, label: "Map", onClick: () => navigate("/map") },
  ];

  const navbarSecondary = [
    /*{
      icon: HiLightningBolt,
      label: "Upgrade account",
      color: colorScheme === "dark" ? "gold" : "goldenrod",
      onClick: () => console.log("upgrade account"),
    },*/ // TODO: add upgrade account page
    /*{
      icon: HiShieldExclamation,
      label: "Admin",
      color:
        colorScheme === "dark"
          ? "var(--mantine-color-orange-7)"
          : "var(--mantine-color-orange-6)",
      onClick: () => navigate("/admin"),
    },*/ // TODO: add admin page
    {
      path: "/account",
      iconComponent: isMobile ? (
        <div></div>
      ) : (
        <div
          className="flex flex-row justify-center items-center h-5 w-5 m-auto my-4
        rounded-full overflow-hidden"
        >
          {getPhotoUrl() ? (
            <img
              className="h-full w-full"
              src={getPhotoUrl()}
              alt=""
            />
          ) : (
            <HiUser
              className="h-full w-full"
              style={{ color: getIconColor(null) }}
            ></HiUser>
          )}
        </div>
      ),
      label: "Account",
      onClick: () => navigate(props.demo ? "/demo/account" : "/account"),
    },
    {
      path: "/settings",
      icon: HiCog,
      label: "Settings",
      onClick: () => navigate(props.demo ? "/demo/settings" : "/settings"),
    },
    {
      icon: HiOutlineLogout,
      label: "Logout",
      onClick: () =>
        openLogoutModal(() => {
          signOut(getAuth()).then(() => {
            setProfile(null);
            setToken(null);
            navigate("/login");
          });
        }),
    },
  ];

  // Remove upgrade account action if environment is private
  /*const isPrivateEnv = process.env.REACT_APP_ENVIRONMENT === "private";
  if (isPrivateEnv) {
    navbarSecondary.splice(0, 1);
  }*/

  const NavbarLink = ({
    icon: Icon,
    iconComponent,
    color,
    label,
    active,
    onClick,
  }: NavbarLinkProps) => {
    return (
      <Tooltip
        disabled={isMobile}
        label={label}
        position="right"
        withArrow
        arrowOffset={5}
        arrowSize={4}
        color="var(--mantine-color-dark-9)"
        transitionProps={{ duration: 200, transition: "fade" }}
      >
        <UnstyledButton
          onClick={onClick}
          className={
            "flex flex-row border-2 border-grey-800" +
            " " +
            (computedColorScheme === "dark"
              ? "link link--dark"
              : "link link--light") +
            (isMobile ? " rounded-lg" : "")
          }
          data-active={active || undefined}
        >
          {iconComponent ? (
            iconComponent
          ) : (
            <div className="flex flex-row justify-start items-center p-4">
              <Icon
                className="flex w-5 h-5"
                style={{
                  color: getIconColor(color),
                }}
              />
              {isMobile ? (
                <Container hidden={!isMobile} className="flex flex-1">
                  {label}
                </Container>
              ) : (
                ""
              )}
            </div>
          )}
        </UnstyledButton>
      </Tooltip>
    );
  };

  const links = navbarPrimary.map((link, index) => (
    <NavbarLink
      {...link}
      key={link.label}
      active={
        link.path === location.pathname ||
        (link?.isActive && link?.isActive(location.pathname))
      }
      onClick={() => {
        if (isMobile) {
          toggle();
        }
        setActive(index);
        link.onClick();
      }}
    />
  ));

  const actions = navbarSecondary.map((link, index) => (
    <NavbarLink
      {...link}
      key={link.label}
      active={link.path === location.pathname}
      onClick={() => {
        if (isMobile) {
          toggle();
        }
        link.onClick();
      }}
    />
  ));

  const getNavbarStyle = () => {
    const baseStyle = isMobile
      ? "flex flex-row justify-start items-center h-14 min-h-14 border-b-2 "
      : "flex flex-col w-full h-full justify-between border-r-2 ";
    const borderStyle =
      computedColorScheme === "dark"
        ? "border-[var(--mantine-color-dark-7)]"
        : "";

    return baseStyle + borderStyle;
  };

  const renderMobileAccountButton = () => {
    if (!isMobile) return null;

    return (
      <div
        className="flex flex-col mt-auto rounded-lg p-4 gap-4 cursor-pointer dark:bg-dark-700 bg-grey-200 dark:hover:bg-dark-900 hover:bg-grey-300"
        onClick={() => {
          if (isMobile) {
            toggle();
          }
          navigate(props.demo ? "/demo/account" : "/account");
        }}
      >
        <div className="flex flex-row justify-start items-center gap-2 p-2 select-none flex-wrap">
          {getPhotoUrl() ? (
            <img
              className="h-10 w-10 min-h-10 min-w-10 m-auto rounded-full overflow-hidden"
              src={getPhotoUrl()}
              alt=""
            />
          ) : (
            <HiUser className="h-10 w-10 min-h-10 min-w-10 m-auto rounded-full overflow-hidden"></HiUser>
          )}

          <div className="flex flex-row justify-between flex-1 flex-start">
            <Container hidden={!isMobile} className="flex flex-col m-0 flex-1">
              <Text fz="lg" fw={500}>
                {getDisplayName()}
              </Text>
              <Text mt={2} fz="md" c="dimmed">
                {getEmail()}
              </Text>
            </Container>
            <div className="flex items-center justify">
              <HiChevronRight className="text-3xl" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Container
        visibleFrom="xs"
        p={0}
        bg={
          computedColorScheme === "dark"
            ? "#111212"
            : "var(--mantine-color-gray-2)"
        }
        className={getNavbarStyle()}
      >
        <div className="flex flex-col">
          <div className="flex flex-col">{links}</div>
        </div>

        <div className="flex flex-col">
          <div className="flex flex-col">{actions}</div>
        </div>
      </Container>

      <Container
        hiddenFrom="xs"
        bg={computedColorScheme === "dark" ? "#111212" : "#111212"}
        className={getNavbarStyle()}
      >
        <div>
          <div
            onClick={toggle}
            className="flex
            hover:bg-grey-900 active:bg-dark-999 cursor-pointer
            rounded-xl p-2 ml-[-0.5rem]"
            data-active={active || undefined}
          >
            <HiMenu className="w-5 h-5 text-grey-400" />
          </div>

          <Drawer
            opened={isMobile && mobileMenuOpened}
            onClose={toggle}
            title={
              <div className="flex items-center gap-1 text-xl">
                <AppLogo size={30}></AppLogo>
                <h1 className="h-full">{process.env.REACT_APP_APPNAME}</h1>
              </div>
            }
          >
            <div className="flex-1">
              <div className="flex flex-col">
                <div className="flex flex-col">{links}</div>
              </div>
              <Divider my={18}></Divider>
              <div className="flex flex-col">
                <div className="flex flex-col">{actions}</div>
              </div>
            </div>

            {renderMobileAccountButton()}
          </Drawer>
        </div>
      </Container>
    </>
  );
}
