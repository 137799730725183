export const demoFolders = [
    {
        "id": "761fb5ba-b2cb-42d1-9eb5-5ab35c9044a9",
        "name": "Christmas in Bucharest",
        "owner": "demo@example.com",
        "parentId": "DEMO",
        "dateCreated": "2024-12-28T20:09:55.141Z",
        "readUsers": [
        ],
        "writeUsers": [
        ],
        "path": "DEMO:DEMO;761fb5ba-b2cb-42d1-9eb5-5ab35c9044a9:Christmas in Bucharest"
    },
    {
        "id": "35a37792-4290-47e8-9734-545901db3952",
        "name": "Quick trip to Bilbao",
        "owner": "demo@example.com",
        "parentId": "DEMO",
        "dateCreated": "2024-12-28T20:09:55.141Z",
        "readUsers": [
        ],
        "writeUsers": [
        ],
        "path": "DEMO:DEMO;35a37792-4290-47e8-9734-545901db3952:Quick trip to Bilbao"
    }
];

export const demoProfile = {
    "id": "656219b8fe03f371f5442a61",
    "name": "Demo User",
    "email": "demo@example.com",
    "storageStatus": {
        "total": 858993459200,
        "used": 27840793736
    },
    "plan": "free",
    "folders": [
        demoFolders[0],
        demoFolders[1],
    ],
    "sharedFolders": []
}
