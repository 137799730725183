import Folder from "../model/Folder";

export const getFolderUrl = (folder: Folder) => {
    if (!folder?.id) return;
    return `${window.location.origin}/browse/${folder.id}`;
};

export const getRemoteControlUrl = (token: string) => {
    return `${window.location.origin}/remote?token=${token}`;
}
