import { useEffect, useState } from "react";
import "./Gallery.css";

import Lightbox, {
  ControllerRef,
  Slide,
  SlideshowRef,
  createModule,
  useLightboxState,
} from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Share from "yet-another-react-lightbox/plugins/share";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import {
  Checkbox,
  Overlay,
  Text,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React from "react";
import {
  HiArrowLeft,
  HiArrowRight,
  HiCake,
  HiDotsVertical,
  HiDownload,
  HiFolder,
  HiOutlineEyeOff,
  HiPause,
  HiPlay,
  HiShare,
  HiUsers,
  HiZoomIn,
  HiZoomOut,
} from "react-icons/hi";
import { HiMiniPaintBrush } from "react-icons/hi2";
import { LuGalleryThumbnails } from "react-icons/lu";
import {
  MdClose,
  MdCloseFullscreen,
  MdFolder,
  MdFolderShared,
  MdFullscreen,
} from "react-icons/md";
import FileEntity from "../../model/File";
import Folder from "../../model/Folder";
import DevDebug from "../loader/DevDebug";
import LoaderMenu from "../menu/LoaderMenu";
import { FILE_OPTIONS_MENU_CONFIG, LOADER_MENU_CONFIG } from "../menu/menus";
import { PiFolderSimpleUserLight } from "react-icons/pi";
import useGlobalState, {
  globalProfile,
  globalSettings,
} from "../../globalState";
import { isTouchScreenDevice } from "../../utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { render } from "@testing-library/react";
import { Settings } from "../../settings";
import User from "../../model/User";
import BottomDrawer from "../bottomSheet/BottomDrawer";
import { socket } from "../../services/socketService";

import PhotoAlbum, { LayoutType } from "react-photo-album";

export enum FILE_STATE {
  LOADED,
  ERROR,
  LOADING,
}

export default function Gallery({
  folders,
  files,
  loadMoreFn,
  onFolderClickFn,
  onActionClick,
  hiddenOptions,
  isMiniGallery,
  isSelectionEnabled = true,
  selectedFiles,
  setSelectedFiles,
  openLightboxAndPlayFromFile,
  setOpenLightboxAndPlayFromFile,
  demo = false,
}: {
  folders: Folder[];
  files: FileEntity[];
  loadMoreFn: () => void;
  onFolderClickFn: (folder: Folder) => void;
  onActionClick;
  hiddenOptions?: boolean;
  isMiniGallery?: boolean;
  isSelectionEnabled?: boolean;
  selectedFiles?: FileEntity[];
  setSelectedFiles?: React.Dispatch<React.SetStateAction<FileEntity[]>>;
  openLightboxAndPlayFromFile?: {
    position?: number;
    file?: any;
    remoteControl?: boolean;
  };
  setOpenLightboxAndPlayFromFile?: React.Dispatch<
    React.SetStateAction<{ position?: number; file?: any }>
  >;
  demo?: boolean;
}) {
  const theme = useMantineTheme();
  const [isOverlay, setIsOverlay] = useState(false);
  const [filesForPhotoAlbum, setFilesForPhotoAlbum] = useState([]);
  const [fileState, setFileState] = useState({});
  const [photoKey, setPhotoKey] = useState({});
  const [lastViewedIndex, setLastViewedIndex] = useState(-1);
  const [profile, setProfile] = useGlobalState<User>(globalProfile);
  const [settings, setSettings] = useGlobalState<Settings>(globalSettings);
  const computedColorScheme = useComputedColorScheme("dark");
  const navigate = useNavigate();
  const { hash } = useLocation();

  const [index, setIndex] = useState(-1);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [isRemoteControl, setIsRemoteControl] = useState(false);

  const slideshowRef = React.useRef<SlideshowRef>(null);
  const controllerRef = React.useRef<ControllerRef>(null);

  const THUMBNAIL_RERENDER_INTERVAL = 4000;

  // Load more plugin build // start
  function LoadMoreComponent({ children, ...props }) {
    const { slides, currentSlide, currentIndex } = useLightboxState();

    useEffect(() => {
      console.log("Slide: " + currentIndex + "/" + (slides?.length - 1));
      if (currentIndex == slides?.length - 1) {
        loadMoreFn();
      }
    }, [currentIndex, slides]);

    return <>{children}</>;
  }

  const loadMoreModule = createModule(
    "LoadMoreModule",
    LoadMoreComponent as any
  );

  function LoadMorePlugin({ addModule }) {
    addModule(loadMoreModule);
  }
  // Load more plugin build // end

  useEffect(() => {
    if (!isRemoteControl) {
      //socket.disconnect();
      return;
    }

    socket.on("receive-command", (data) => {
      console.log("Received:", data.command);

      if (data.command === "PLAY") {
        play();
      }

      if (data.command === "PAUSE") {
        pause();
      }

      if (data.command === "NEXT") {
        next();
      }

      if (data.command === "PREVIOUS") {
        prev();
      }
    });
  }, [isRemoteControl]);

  useEffect(() => {
    const mappedPhotos = files.map((file: FileEntity) => {
      const isVideo = file.name.endsWith(".mp4");

      if (isVideo) {
        return {
          ...file,
          width: file.width ?? 1280,
          height: file.height ?? 720,
          src: file.thumbnailUrl,
        };
      }

      return {
        ...file,
        width: file.width ?? 1280,
        height: file.height ?? 720,
        src: file.thumbnailUrl,
      };
    });

    setFileState((oldFs) => {
      const newFs = {};
      mappedPhotos.forEach((mp) => {
        if (oldFs[mp.id] !== undefined && oldFs[mp.id] !== null) {
          newFs[mp.id] = oldFs[mp.id];
          return;
        }
        newFs[mp.id] = FILE_STATE.LOADING;
      });
      return newFs;
    });
    setPhotoKey(mappedPhotos.map((_) => Date.now()));

    setFilesForPhotoAlbum(mappedPhotos);
  }, [files]);

  useEffect(() => {
    if (!openLightboxAndPlayFromFile) return;
    if (
      !openLightboxAndPlayFromFile.file &&
      openLightboxAndPlayFromFile.position === undefined
    )
      return;

    let index = 0;

    if (openLightboxAndPlayFromFile.file) {
      index = files.findIndex(
        (f) => f.id === openLightboxAndPlayFromFile.file.id
      );
    }

    if (openLightboxAndPlayFromFile.position !== undefined) {
      index = openLightboxAndPlayFromFile.position;
    }

    setIndex(index);

    if (openLightboxAndPlayFromFile.remoteControl) {
      // Pause slideshow
      setTimeout(() => {
        if (slideshowRef.current?.playing) {
          slideshowRef.current.pause();
        }
      }, 1000);

      setIsRemoteControl(true);
    } else {
      // Play slideshow
      setTimeout(() => {
        if (!slideshowRef.current?.playing) {
          slideshowRef.current.play();
        }
      }, 1000);
    }
  }, [openLightboxAndPlayFromFile]);

  const filesForLightbox: Slide[] = files.map((file: FileEntity) => {
    const isVideo = file.name.endsWith(".mp4");

    if (isVideo) {
      return {
        type: "video" as const,
        title: file.name,
        width: file.width ?? 1280,
        height: file.height ?? 720,
        poster: file.thumbnailUrl,
        sources: [
          {
            src: file.url,
            type: "video/mp4",
          },
        ],
      };
    }

    return {
      ...file,
      src: file.url,
    };
  });

  function next() {
    controllerRef.current?.next();
  }

  function prev() {
    controllerRef.current?.prev();
  }

  function play() {
    slideshowRef.current?.play();
  }

  function pause() {
    slideshowRef.current?.pause();
  }

  function onImgLoad(e: any, photo: any) {
    setFileState((fs) => ({ ...fs, [photo.id]: FILE_STATE.LOADED }));
  }

  function onImgError(e: any, photo: any) {
    setFileState((fs) => ({ ...fs, [photo.id]: FILE_STATE.ERROR }));

    // Trigger photo key update to force re-render
    setTimeout(() => {
      setPhotoKey((pk) => ({ ...pk, [photo.id]: Date.now() }));
    }, THUMBNAIL_RERENDER_INTERVAL);
  }

  function getPhotoClassName(photo: any) {
    let className =
      "photo overflow-hidden relative cursor-pointer transition-all box-border";

    if (filesForPhotoAlbum[lastViewedIndex]?.id === photo.id) {
      className += " border-4 border-blue-500";
    }

    if (
      isSelectionEnabled &&
      (selectedFiles?.includes(photo.id) || selectedFiles === null)
    ) {
      className += " border-2 border-blue-500";
    }

    if (fileState[photo.id] === FILE_STATE.ERROR) {
      className += " photo--error";
    }
    if (fileState[photo.id] === FILE_STATE.LOADED) {
      className += " photo--loaded";
    }
    className += " photo--loading";

    return className;
  }

  function getPhotoErrorClassName(photo: any) {
    let className =
      "w-full h-full dark:bg-dark-700 bg-grey-200 flex flex-col items-center justify-center gap-2 text-grey-600 hover:bg-dark-800";

    return fileState[photo.id] === FILE_STATE.ERROR
      ? className + " visible"
      : "invisible h-0 w-0";
  }

  function getPhotoLoadingClassName(photo: any) {
    let className = "w-full h-full dark:bg-dark-700 bg-grey-200 animate-pulse";

    return fileState[photo.id] !== FILE_STATE.ERROR &&
      fileState[photo.id] !== FILE_STATE.LOADED
      ? className + " visible"
      : "invisible h-0 w-0";
  }

  function onPhotoClick(photo: any) {
    if (
      isSelectionEnabled &&
      ((!!selectedFiles && selectedFiles.length > 0) || selectedFiles === null)
    ) {
      if (selectedFiles === null) {
        return;
      }
      if (selectedFiles.includes(photo.id)) {
        setSelectedFiles(selectedFiles.filter((f) => f !== photo.id));
      } else {
        setSelectedFiles([...selectedFiles, photo.id]);
      }
      return;
    }

    const photoIndex = filesForPhotoAlbum.findIndex((p) => p.id === photo.id);
    setIndex(photoIndex);
  }

  function getGalleryItemMaxWidth() {
    // TODO: IMPROVE
    //if (isMobile) return "100%";
    //if (filesForPhotoAlbum.length < 10) return "15rem";
    return "100%";
  }

  function getPhotoKey(photo: any) {
    return photoKey[photo.id];
  }

  function renderFileOptions(photo: any) {
    if (hiddenOptions) return null;

    let content;

    if (isMobile) {
      content = (
        <BottomDrawer
          ctx={{
            file: { ...photo },
            demo,
          }}
          menuConfig={FILE_OPTIONS_MENU_CONFIG}
          onActionClick={onActionClick}
          targetElement={
            <div
              className={`${
                isTouchScreenDevice()
                  ? "photo-options--mobile"
                  : "photo-options"
              } flex justify-center items-center p-2 hover:bg-dark-900 active:bg-dark-999 cursor-pointer rounded-xl`}
            >
              <HiDotsVertical className="flex w-4 h-4"></HiDotsVertical>
            </div>
          }
        ></BottomDrawer>
      );
    } else {
      content = (
        <LoaderMenu
          ctx={{
            file: { ...photo },
            demo,
          }}
          menuConfig={FILE_OPTIONS_MENU_CONFIG}
          onActionClick={onActionClick}
          targetElement={
            <div
              className={`${
                isTouchScreenDevice()
                  ? "photo-options--mobile"
                  : "photo-options"
              } flex justify-center items-center p-2 hover:bg-dark-900 active:bg-dark-999 cursor-pointer rounded-xl`}
            >
              <HiDotsVertical className="flex w-4 h-4"></HiDotsVertical>
            </div>
          }
          onOpened={() => setIsOverlay(true)}
          onClosed={() => setIsOverlay(false)}
        ></LoaderMenu>
      );
    }

    return (
      <div
        className="absolute top-1 right-1 z-10"
        onClick={(e) => e.stopPropagation()}
      >
        {content}
      </div>
    );
  }

  function renderVideoIconOverlay(photo: any) {
    const isVideo = photo.name.endsWith(".mp4");
    if (isVideo) {
      return (
        <HiPlay className="photo-album-media-container__video-indicator absolute text-5xl opacity-60 hover:opacity-80"></HiPlay>
      );
    }
  }

  function renderFileSelectionCheckbox(photo: any) {
    if (hiddenOptions) return null;
    if (!isSelectionEnabled) return null;

    if (!!selectedFiles && selectedFiles.includes(photo.id)) {
      return (
        <div
          className="absolute top-2 left-2 z-10"
          onClick={(e) => e.stopPropagation()}
        >
          <Checkbox
            className={`$text-blue-400 cursor-pointer`}
            checked={selectedFiles.includes(photo.id)}
            onClick={() => {
              setSelectedFiles(selectedFiles.filter((f) => f !== photo.id));
            }}
          />
        </div>
      );
    }

    return (
      <div
        className="absolute top-2 left-2 z-10"
        onClick={(e) => e.stopPropagation()}
      >
        <Checkbox
          className={`${
            isTouchScreenDevice() ? "photo-options--mobile" : "photo-options"
          } text-blue-400 cursor-pointer opacity-25`}
          checked={selectedFiles === null}
          onClick={() => {
            if (!!selectedFiles) {
              setSelectedFiles([...selectedFiles, photo.id]);
            }
          }}
        />
      </div>
    );
  }

  function mapPhotoLayoutClassName(layout: LayoutType, className: string) {
    if (layout === "rows") {
      return `${className}--row `;
    }

    if (layout === "columns") {
      return `${className}--col`;
    }

    if (layout === "masonry") {
      return `${className}--mas`;
    }
  }

  function parseImageSrc(src: string) {
    if (src.startsWith("http")) {
      return src;
    }
    return `${window.location.origin}/${src}`;
  }

  function renderPhotoAlbum() {
    return (
      <PhotoAlbum
        photos={filesForPhotoAlbum as any}
        layout={settings.photoAlbum_layout}
        spacing={settings.photoAlbum_spacing}
        targetRowHeight={
          !!isMiniGallery
            ? settings.photoAlbum_targetRowHeight * 1
            : settings.photoAlbum_targetRowHeight
        }
        renderPhoto={({
          photo,
          wrapperStyle,
          imageProps: { src, alt, style, ...restImageProps },
        }) => (
          <div
            className={getPhotoClassName(photo)}
            style={{ ...wrapperStyle, maxWidth: getGalleryItemMaxWidth() }}
            onClick={() => onPhotoClick(photo)}
          >
            <div className={getPhotoErrorClassName(photo)}>
              <HiMiniPaintBrush className="size-8" />
              <Text size="xs" className="text-center">
                Generating thumbnail...
              </Text>
            </div>

            <div className={getPhotoLoadingClassName(photo)}></div>

            <div className="photo-album-media-container flex justify-center items-center">
              <img
                key={getPhotoKey(photo)}
                src={parseImageSrc(src)}
                alt={alt}
                style={style}
                onLoad={(e) => onImgLoad(e, photo)}
                onError={(e) => onImgError(e, photo)}
                {...restImageProps}
              />

              {renderVideoIconOverlay(photo)}
            </div>

            {renderFileOptions(photo)}
            {renderFileSelectionCheckbox(photo)}
          </div>
        )}
      />
    );
  }

  function getNavigationButtonClassName() {
    if (isTouchScreenDevice()) return "opacity-0";
    return "opacity-50 hover:opacity-100";
  }

  // TODO: make configurable through settings
  function renderBottomControls() {
    return null;
    return (
      <div
        className="fixed bottom-2 right-auto left-auto
      flex flex-row gap-4 justify-center items-center
      border-2 border-grey-700
      w-fit h-10 p-2 rounded-lg
      bg-grey-900 bg-opacity-50
      opacity-50 hover:opacity-100
      "
      >
        <HiArrowLeft className="text-3xl opacity-50 hover:opacity-100 cursor-pointer"></HiArrowLeft>
        <HiArrowRight className="text-3xl opacity-50 hover:opacity-100 cursor-pointer"></HiArrowRight>
      </div>
    );
  }

  const onLightboxClose = () => {
    if (!!setOpenLightboxAndPlayFromFile) {
      setOpenLightboxAndPlayFromFile(null);

      // Pause slideshow
      if (slideshowRef.current?.playing) {
        slideshowRef.current?.pause();
      }
    }

    setIndex(-1);
  };

  function renderLightbox() {
    return (
      <Lightbox
        slides={filesForLightbox}
        open={index >= 0}
        index={index}
        on={{
          entered: () => {
            setIsLightboxOpen(true);
          },
          exited: () => {
            setIsLightboxOpen(false);
            //window.location.replace("#"); // remove the hash

            /*setTimeout(() => {
              setLastViewedIndex(-1);
            }, 20000);*/
          },
        }}
        toolbar={{
          buttons: [
            <button
              key="download-button"
              type="button"
              className="yarl__button"
            >
              <HiDownload size={18}></HiDownload>
            </button>,
            <button key="share-button" type="button" className="yarl__button">
              <HiShare size={18}></HiShare>
            </button>,
            <div className="mx-0 xs:mx-2"></div>,
            "close",
          ],
        }}
        close={() => onLightboxClose()}
        carousel={{
          finite: true,
          preload: 1,
        }}
        plugins={[
          Fullscreen,
          Slideshow,
          //Thumbnails, // TODO: loads full images?
          Zoom,
          //LoadMorePlugin, // TODO: fix infinite loop error when only 1 image gallery
          Video,
          //Share,
        ]}
        slideshow={{ ref: slideshowRef, delay: settings.slideshow_delay }}
        thumbnails={{
          padding: 0,
          border: 0,
          imageFit: "cover",
          showToggle: true,
        }}
        controller={{
          ref: controllerRef,
          closeOnBackdropClick: false, // TODO: make configurable
          closeOnPullDown: true,
          closeOnPullUp: true,
        }}
        render={{
          controls: renderBottomControls,
          iconClose: () => <MdClose size={24}></MdClose>,
          iconShare: () => <HiShare size={24}></HiShare>,
          iconEnterFullscreen: () => <MdFullscreen size={24}></MdFullscreen>,
          iconExitFullscreen: () => (
            <MdCloseFullscreen size={24}></MdCloseFullscreen>
          ),
          iconZoomIn: () => <HiZoomIn size={24}></HiZoomIn>,
          iconZoomOut: () => <HiZoomOut size={24}></HiZoomOut>,
          iconSlideshowPlay: () => <HiPlay size={24}></HiPlay>,
          iconSlideshowPause: () => <HiPause size={24}></HiPause>,
          iconThumbnailsVisible: () => (
            <LuGalleryThumbnails size={24}></LuGalleryThumbnails>
          ),
          iconThumbnailsHidden: () => (
            <LuGalleryThumbnails size={24}></LuGalleryThumbnails>
          ),
          iconPrev: () => (
            <HiArrowLeft
              className={getNavigationButtonClassName()}
              size={24}
            ></HiArrowLeft>
          ),
          iconNext: () => (
            <HiArrowRight
              className={getNavigationButtonClassName()}
              size={24}
            ></HiArrowRight>
          ),
        }}
      />
    );
  }

  function isSharedFolder(folder: Folder) {
    return folder.owner != profile.email;
  }

  function renderFolderIcon(folder: Folder) {
    if (isMobile) {
      return (
        <div className="relative">
          {isSharedFolder(folder) ? (
            <MdFolderShared className="text-2xl" />
          ) : (
            <MdFolder className="text-2xl text-blue-400" />
          )}
        </div>
      );
    }

    if (isSharedFolder(folder)) {
      return (
        <div className="relative">
          <HiFolder className="xs:text-7xl text-2xl" />
          <HiUsers
            className="absolute text-xs text-dark-600 bottom-1 right-2
            xs:text-dark-900 xs:bottom-5 xs:right-4 xs:text-lg"
          />
        </div>
      );
    }

    return <HiFolder className="xs:text-7xl text-2xl text-blue-400" />;
  }

  const getFolderClassName = (folder: Folder) => {
    const isDarkMode = computedColorScheme === "dark";
    let colorOptions: string;
    let borderOptions: string = "";

    if (isDarkMode) {
      colorOptions = "border-b border-grey-800 hover:bg-grey-900 text-grey-300";
    } else {
      colorOptions = "border-b border-grey-200 hover:bg-grey-100 text-grey-900";
    }

    if (!isMobile) {
      borderOptions = "rounded-lg";
    }

    return `folder ${colorOptions} ${borderOptions}
      flex justify-start items-center gap-2 py-2 px-4 cursor-pointer 
      xs:border-none xs:w-52 xs:flex-col xs:gap-0 xs:text-center
      relative`;
  };

  const getFolderOptionsClassName = (folder: Folder) => {
    const isDarkMode = computedColorScheme === "dark";
    let colorOptions: string;

    if (isDarkMode) {
      colorOptions = "hover:bg-dark-900 hover:bg-dark-600 active:bg-dark-999";
    } else {
      colorOptions = "hover:bg-grey-300 active:bg-grey-400";
    }

    const folderOptions = isTouchScreenDevice()
      ? "folder-options--mobile"
      : "folder-options";

    return `${folderOptions} ${colorOptions} 
      flex justify-center items-center p-2 cursor-pointer rounded-xl`;
  };

  const renderFolderOptions = (folder: Folder) => {
    if (hiddenOptions) return null;

    let content;

    if (isMobile) {
      content = (
        <BottomDrawer
          ctx={{ folder, demo }}
          menuConfig={LOADER_MENU_CONFIG}
          onActionClick={onActionClick}
          targetElement={
            <div className={getFolderOptionsClassName(folder)}>
              <HiDotsVertical className="flex w-4 h-4"></HiDotsVertical>
            </div>
          }
        ></BottomDrawer>
      );
    } else {
      content = (
        <LoaderMenu
          ctx={{ folder, demo }}
          menuConfig={LOADER_MENU_CONFIG}
          onActionClick={onActionClick}
          targetElement={
            <div className={getFolderOptionsClassName(folder)}>
              <HiDotsVertical className="flex w-4 h-4"></HiDotsVertical>
            </div>
          }
          onOpened={() => setIsOverlay(true)}
          onClosed={() => setIsOverlay(false)}
        ></LoaderMenu>
      );
    }

    return (
      <div
        className="absolute top-1 right-1 pr-1"
        onClick={(e) => e.stopPropagation()}
      >
        {content}
      </div>
    );
  };

  const renderFolders = () => {
    if (folders?.length === 0) return <></>;

    return (
      <div className={`flex flex-col xs:flex-row xs:flex-wrap mb-4`}>
        {[...folders] // sort mutates the array
          .sort((a, b) => a.name.localeCompare(b.name, "en", { numeric: true }))
          .map((folder) => {
            return (
              <div
                key={folder.id}
                onClick={() => onFolderClickFn(folder as any)}
                className={getFolderClassName(folder)}
              >
                {renderFolderIcon(folder)}

                {folder.name}

                {renderFolderOptions(folder)}
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div className="">
      {isOverlay && (
        <Overlay
          color={computedColorScheme === "dark" ? "#000" : "#aaa"}
          backgroundOpacity={0.45}
          blur={1}
        />
      )}
      {renderFolders()}

      {renderPhotoAlbum()}
      {renderLightbox()}

      {/*<div className="absolute bottom-0 w-full">
        <DevDebug props={{ fileState }}></DevDebug>
      </div>*/}
    </div>
  );
}
