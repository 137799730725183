import { Container, Text, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import { HiChevronLeft, HiChevronRight, HiPlay } from "react-icons/hi";
import { socket } from "../../services/socketService";

enum Command {
  PLAY = "PLAY",
  PAUSE = "PAUSE",
  NEXT = "NEXT",
  PREVIOUS = "PREVIOUS",
}

export default function RemoteControl() {
  const [rcTocken, setRcTocken] = useState(null);

  const connect = (tokenForConnection: string) => {
    socket.emit("frontend-b-init", { tokenForConnection });
  };

  const sendCommand = (command: Command) => {
    console.log("Sending command", { rcTocken, command });
    socket.emit("send-command", { rcTocken, command });
  };

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  useEffect(() => {
    const remoteControlToken = new URLSearchParams(window.location.search).get(
      "token"
    );
    console.log(remoteControlToken);
    if (remoteControlToken) {
      setRcTocken(remoteControlToken);
      connect(remoteControlToken);
    } else {
      console.error("Remote control token not found");
    }
  }, []);

  return (
    <Container className="w-full h-full flex flex-col" p={isMobile ? "md" : "xl"}>
      <div className="flex flex-column justify-between items-center">
        <Text fz="xl" className="title" fw={500}>
          Remote control
        </Text>
        {socket.connected ? (
          <div className="flex flex-row gap-2 justify-center items-center">
            <div className="bg-green-500 rounded-full w-2 h-2 animate-pulse"></div>
            <Text fz="md" c="dimmed">
              Connected
            </Text>
          </div>
        ) : (
          <div className="flex flex-row gap-2 justify-center items-center">
            <div className="bg-red-500 rounded-full w-2 h-2"></div>
            <Text fz="md" c="dimmed">
              Not connected
            </Text>
          </div>
        )}
      </div>

      <div className="flex flex-col justify-between h-full w-full">
        <div
          className="mt-8 card flex  flex-col items-center justify-center gap-2 hover:bg-grey-700 active:bg-grey-800 cursor-pointer"
          onClick={() => sendCommand(Command.PLAY)}
        >
          <HiPlay size={80} />
        </div>

        <div className="w-full flex flex-row justify-between gap-2 pb-6 flex-1">
          <div
            className="mt-8 card flex items-center justify-center gap-2 flex-1 hover:bg-grey-700 active:bg-grey-800 cursor-pointer"
            onClick={() => sendCommand(Command.PREVIOUS)}
          >
            <HiChevronLeft size={80} />
          </div>
          <div
            className="mt-8 card flex items-center justify-center gap-2 flex-1 hover:bg-grey-700 active:bg-grey-800 cursor-pointer"
            onClick={() => sendCommand(Command.NEXT)}
          >
            <HiChevronRight size={80} />
          </div>
        </div>
      </div>
    </Container>
  );
}
