import {
  Switch,
  useComputedColorScheme,
  useMantineColorScheme,
} from "@mantine/core";
import React from "react";

export default function DemoBanner() {
  const { setColorScheme, clearColorScheme } = useMantineColorScheme({
    keepTransitions: true,
  });
  const computedColorScheme = useComputedColorScheme("dark");

  const toggleDarkMode = () => {
    setColorScheme(computedColorScheme === "dark" ? "light" : "dark");
  };

  return (
    <div className="flex flex-row justify-between items-center h-16 w-full bg-grey-200 dark:bg-dark-400 border-b-2 border-blue-500">
      <div className="flex flex-row justify-between items-center w-full max-w-screen-lg m-auto">
        <a
          href="https://www.picnube.com"
          className="flex flex-row items-center gap-2 text-white"
        >
          <h1 className="text-white text-2xl font-bold pl-4">logo</h1>
        </a>

        {/*<Switch
          onLabel="ON"
          offLabel="OFF"
          className="switch"
          size="md"
          defaultChecked={computedColorScheme === "dark"}
          onChange={(value) => toggleDarkMode()}
        />*/}

        <div
          className="hidden xs:flex
        flex-1 mx-4 w-full justify-end"
        >
          <p className="">Sign up and unlock all features</p>
        </div>

        <button
          className="w-40 sm:w-52 h-10 mr-4
        rounded-lg font-bold bg-primaryColor text-white flex justify-center items-center hover:bg-[#0556a2] cursor-pointer transition"
          onClick={() => {}}
          aria-label="Get started"
        >
          Get Started
        </button>
      </div>
    </div>
  );
}
