import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { demoProfile } from '../mock/demoData';
import CreateFolderDto from '../model/CreateFolderDto';
import FileMetadata from '../model/FileMetadata';
import { FileMetadataUploadResponseDto } from '../model/FileMetadataUploadResponseDto';
import Folder from '../model/Folder';
import { UpdateFolderAccessDto } from '../model/UpdateFolderAccessDto';

export enum APIError {
    NOT_FOUND = 'NOT_FOUND',
    ACCESS_DENIED = 'ACCESS_DENIED',
}

const useApiDemo = () => {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    //const isDevEnv = process.env.NODE_ENV === 'development';
    //const API_BASE_URL = isDevEnv ? '' : `${process.env.REACT_APP_API_BASE_URL}/`
    const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/demo`
    const API_PATH_PROFILE = API_BASE_URL + process.env.REACT_APP_API_PATH_PROFILE
    const API_PATH_FOLDERS = API_BASE_URL + process.env.REACT_APP_API_PATH_FOLDERS
    const API_PATH_UPLOAD = API_BASE_URL + process.env.REACT_APP_API_PATH_UPLOAD
    const API_PATH_FILES = API_BASE_URL + process.env.REACT_APP_API_PATH_FILES

    const fetchProfile = async () => {
        return demoProfile;
    }

    const fetchFiles = async (folderId: string, page: number) => {
        return [];
    }

    const searchFiles = async (page: number) => {
        return [];
    }

    const fetchFolder = async (folderId: string, includeFiles: boolean) => {
        try {
            setLoading(true);

            const response: Response = await fetch(
                `${API_PATH_FOLDERS}/${folderId}?` + new URLSearchParams({
                    includeFiles: '' + includeFiles,
                }),
                {
                    method: 'GET',
                }
            );

            if (!response?.ok) {
                throw new Error();
            }

            const resolvedData = await response.json();
            setLoading(false);

            return resolvedData;

        } catch (e) {
            console.error(e);
            console.log(e.message);
            setLoading(false);

            if (e.message === 'Folder not found') {
                return APIError.NOT_FOUND;
            }
            if (e.message === 'Access denied') {
                return APIError.ACCESS_DENIED;
            }

            return null;

        } finally {
            setLoading(false);
        }
    }

    const upsertFolder = async (folder: Folder): Promise<Folder> => {
        return null;
    }

    const getFolderAccessInfo = async (folderId: string) => {
        return null;
    }

    const updateFolderAccess = async (folderId: string, request: UpdateFolderAccessDto) => {
        return null;
    }

    const deleteFolder = async (folderId: string) => {
        return null;
    }

    const deleteFile = async (fileId: string) => {
        return null;
    }

    // TODO: Rename to uploadMetadata
    const uploadFiles = async (files: FileMetadata[], folders: CreateFolderDto[]): Promise<FileMetadataUploadResponseDto> => {
        return null;
    }

    const requestFilesUpload = async (filesUploadRequest: any) => {
        return null;
    }

    const login = async (email: string, password: string): Promise<boolean> => {
        return true;
    }

    const register = async (name: string, email: string, password: string) => {
        return;
    }

    return {
        fetchProfile,
        fetchFiles,
        fetchFolder,
        upsertFolder,
        uploadFiles,
        searchFiles,
        requestFilesUpload,
        updateFolderAccess,
        getFolderAccessInfo,
        deleteFile,
        deleteFolder,
        login,
        register,
        loading,
    };
};

export default useApiDemo;
