import {
  ActionIcon,
  Button,
  Divider,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useState } from "react";
import { HiArrowRight, HiCheck, HiClipboardCopy } from "react-icons/hi";
import QRCode from "react-qr-code";
import useGlobalState, { globalProfile } from "../../globalState";
import { getRemoteControlUrl } from "../../utils/UrlUtils";

export default function RemoteControlConnectionModal(props: {
  token: string;
  onConfirm: any;
}) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const [profile, setProfile] = useGlobalState(globalProfile);
  const [copied, setCopied] = useState(false);

  const renderManageAccessButton = () => {
    return (
      <>
        <Divider className="mt-3"></Divider>
        <div className="flex flex-col gap-4">
          <Button
            className="flex w-full"
            variant="filled"
            leftSection={<HiArrowRight />}
            onClick={async () => props?.onConfirm()}
          >
            Continue
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col gap-4 overflow-hidden">
      <div className="flex flex-row gap-2 justify-center items-center text-2xl mb-2">
        {"Scan QR code to remote control slideshow from another device"}
      </div>

      <div className="max-w-52 m-auto mb-4 w-full h-full flex justify-center items-center p-3 bg-white">
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={getRemoteControlUrl(props.token)}
          viewBox={`0 0 256 256`}
        />
      </div>

      <div className="flex flex-row justify-start items-center">
        <Text className="text-center text-xs">
          Or open the link in another device
        </Text>
      </div>

      <div className="flex flex-row gap-3 w-full justify-between items-center">
        <TextInput className="flex-1" value={getRemoteControlUrl(props.token)} />
        <div className="flex flex-row justify-center items-center h-full">
          <Tooltip
            label={copied ? "Copied" : "Copy"}
            withArrow
            position="right"
          >
            <ActionIcon
              className="flex "
              color={copied ? "teal" : "gray"}
              variant="subtle"
              onClick={() => {
                setCopied(true);
                navigator.clipboard.writeText(getRemoteControlUrl(props.token));
              }}
            >
              {copied ? <HiCheck /> : <HiClipboardCopy />}
            </ActionIcon>
          </Tooltip>
        </div>
      </div>

      {renderManageAccessButton()}
    </div>
  );
}
